import React from 'react'

import './aboutUs.css';

function AboutUs() {
    return (
        <div className="Content" id="about-us">
            <div className="AboutUs">
            <div className="AboutUsTitle">
                Who We Are?
            </div>
            <div className="AboutUsContent">
                Our company focus on three stream - software, hardware and printing - in 
                Addis Ababa, Ethiopia. YamGet's mission is to easy the burden of socities via integrating 
                innovative technologies. The software department delivers comprehensive web based and mobile 
                application that can enhance the performance of our client business. Our web technology services 
                ranging from custom website design to complex enterprise level web applications. 
                The hardware department working on office machine maintenance service, network 
                installation, and consulation on IT infrastracture. The printing department works on designing, 
                paper and digital printing, smart ID card printing, and sign board printing.
            </div>
            </div>
        </div>
    )
}

export default AboutUs;