import React from 'react'

import Organization from './Organization';

import Everest from '../../assets/company_logo/everest.png';
import Champion from '../../assets/company_logo/champions-acadamy.png';
import Ethiopia from '../../assets/company_logo/Ethiopia.png';
import AradaManufacturing from '../../assets/company_logo/Arada-manufacturing-college.png';
import KidsParadise from '../../assets/company_logo/KidsParadise.png';
import EHIA from '../../assets/company_logo/Ethiopia-health-insurance-agency.png';
import './workedWith.css';

function WorkedWith() {
    const organizations = [
        { id: 1, name: "Everest", logo: Everest },
        { id: 2, name: "Champion", logo: Champion },
        { id: 3, name: "Kids Paradise School", logo: KidsParadise },
        { id: 4, name: "Arada Manufacturing College", logo: AradaManufacturing },
        { id: 5, name: "Ethiopia", logo: Ethiopia },
        { id: 6, name: "Ethiopia Health Insurance Agency", logo: EHIA },
    ];
    return (
        <div className="WorkedWith" id="clients">
            WE'VE BEEN TRUSTED BY
            <div className="OrganizationList">
                { organizations.map(org => <Organization key={org.id} orgInfo={org} />) }
            </div>
        </div>
    )
}

export default WorkedWith;